import {
  appNotification,
  newsletter,
  dataFilters,
  cases,
  video,
  article,
  live_event,
  speciality,
  adminUser,
  campaign,
  masterUrl,
  post,
} from "../../js/path";
import moment from "moment";
import _ from "lodash";

export default {
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    toolTip() {
      return this.tooltip1 + this.tooltip2;
    },
    whatsappConfigOptions() {
      let data = [{ endpoint_name: "", displayText: "Default" }];
      this.whatsappConfigNumbers.forEach((value) => {
        data.push({
          endpoint_name: value.endpoint_name,
          displayText: `${value.number} (${value.speciality})`,
        });
      });
      return data;
    },
  },
  data() {
    return {
      createdByArr: [
        {
          name: "whatsapp",
          label: "Whatsapp",
        },
        {
          name: "app_notification",
          label: "App Notification",
        },
        {
          name: "email",
          label: "Email",
        },
        {
          name: "sms",
          label: "SMS",
        },
      ],
      dashboerd_count: 0,
      dashboerd_data: {},
      specialityStatus: false,
      table_header: [],
      tipData: {
        title:
          "1. If you want to add Title use {{ title }} in variable. <br> 2. If you want to add UTM Link use {{ utm_link }} in variable. <br> 3. If you want to add App Link use {{ app_link }} in variable.",
      },
      getTouchpointAnalytics: "",
      hasData: false,
      noData: false,
      touchpoint_id: null,
      showData: false,
      campaign_id: this.$route.params.id,
      username: "",
      params: "&page=1",
      new_param: false,
      activeTab: 4,
      getAdminUser: [],
      pageNo: null,
      isApp: false,
      isWhatsapp: false,
      whatsappReport: [],
      checkContent: false,
      selectedVariable: {},
      getWhatsappTemplate: [],
      count: 0,
      is_check: false,
      channelType: null,
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
          visible: true,
        },
        {
          key: "engagement_name",
          label: "Touchpoint Name",
          visible: true,
        },
        {
          key: "dasboard",
          label: "Dasboard",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "notification_type",
          label: "Channel",
          visible: true,
        },
        {
          key: "action_type",
          label: "Targeted Content",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "target_datafilter",
          label: "Target HCPs set",
          visible: true,
        },
        {
          key: "scheduled_time",
          label: "Scheduled At",
          class: "c_touchpt_header",
          visible: true,
          formatter: (value) => {
            return moment(String(value)).format("llll");
          },
        },
        {
          key: "created_at",
          label: "Created At",
          class: "c_touchpt_header",
          visible: true,
          formatter: (value) => {
            return moment(String(value)).format("llll");
          },
        },
        {
          key: "created_by",
          label: "Created By",
          visible: true,
        },
        {
          key: "created_from",
          label: "Created From",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "is_processed",
          label: "Status",
          visible: true,
        },
        {
          key: "result",
          visible: false,
        },
        {
          key: "edit",
          visible: true,
        },
        {
          key: "delete",
          visible: true,
        },
      ],
      actionType: [],
      deviceType: [
        {
          text: "Android",
          value: "android",
        },
        {
          text: "IOS",
          value: "ios",
        },
        {
          text: "All",
          value: "all",
        },
      ],
      tableData: [],
      key: 0,
      currentPage: 1,
      filter: "",
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      attachType: [],
      attachment: [],
      report: {
        notification_type: "",
        result: null,
      },
      form: {
        template_id: "",
        variable_count: 0,
        engagement_name: "",
        scheduled_time: "",
        notification_type: "whatsapp",
        send_to_medium: "",
        mobile_numbers: "",
        action_type: "",
        action_id: "",
        content: "",
        device_type: "",
        contentParams: "",
        image: "",
        data_filter_id: "",
        notification_title: "",
        notification_description: "",
        variables: [],
        speciality_selected: [],
        from_number: "",
      },
      oldForm: {},
      csv_file: "",
      image: "",
      image_url: "",
      edit: {
        csv_file_url: null,
        image_url: null,
      },
      isDataFilter: false,
      isTestMob: false,
      isSpeciality: false,
      isCSV: false,
      dataFilter: [],
      specialities: [],
      action_type: [],
      action_key: 0,
      typeOpt: [
        {
          text: "Whatsapp",
          value: "whatsapp",
        },
        {
          text: "App Notification",
          value: "app_notification",
        },
        {
          text: "SMS",
          value: "sms",
          disabled: true,
        },
        {
          text: "Email",
          value: "email",
          disabled: true,
        },
      ],
      sendToMedium: [],
      whatsappConfigNumbers: [],
    };
  },
  methods: {
    async getWhatsappConfigNumbers() {
      const url = masterUrl.getWhatsappConfigNumbers;
      const data = await this.getRequest(url);
      if (data.status) {
        this.whatsappConfigNumbers = data.response;
      }
    },
    disabledDates() {
      return new Date().toISOString().slice(0, 16);
    },
    resetInput() {
      this.username = "";
      if (this.$route.name == "view-single-campaign") {
        this.fetchData(this.activeTab);
      } else if (this.$route.name == "touchpoint-list") {
        this.fetchTouchpointData(this.activeTab);
      }
    },

    resetChannelType() {
      this.channelType = null;
      if (this.$route.name == "view-single-campaign") {
        this.fetchData(this.activeTab);
      } else if (this.$route.name == "touchpoint-list") {
        this.fetchTouchpointData(this.activeTab);
      }
    },

    getCreatedByUser() {
      console.log(this.channelType, "channel type", this.username, "username");
      if (this.$route.name == "view-single-campaign") {
        this.fetchData(this.activeTab);
      } else if (this.$route.name == "touchpoint-list") {
        this.fetchTouchpointData(this.activeTab);
      }
    },
    async fetchTouchpointData(txt) {
      let url = null;
      if (txt == 4) {
        this.activeTab = txt;
        url =
          appNotification.appNotificationUrl +
          "?created_by=" +
          (this.username.id || "") +
          "&search=" +
          this.filter;

        if (this.channelType && this.channelType.name) {
          url += "&channel_type=" + this.channelType.name;
        } else {
          url += "&channel_type=" + "";
        }
      } else {
        this.activeTab = txt;
        url =
          appNotification.appNotificationUrl +
          "?is_processed=" +
          txt +
          "&created_by=" +
          (this.username.id || "") +
          "&search=" +
          this.filter;
        if (this.channelType && this.channelType.name) {
          url += "&channel_type=" + this.channelType.name;
        } else {
          url += "&channel_type=" + "";
        }
      }
      url += this.params;
      this.$store.commit("loader/updateStatus", true);
      try {
        const data = await this.getRequest(url);
        if (data.status) {
          this.showData = true;
          this.isPaginate = true;
          const responseData = data.response;
          this.tableData = responseData;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
      this.key += 1;
    },
    async fetchData(txt) {
      let url = null;
      if (txt == 4) {
        this.activeTab = txt;
        url =
          campaign.fetchSingleTouchpoint +
          "/" +
          this.$route.params.id +
          "?created_by=" +
          (this.username.id || "") +
          "&search=" +
          this.filter;
        if (this.channelType && this.channelType.name) {
          url += "&channel_type=" + this.channelType.name;
        } else {
          url += "&channel_type=" + "";
        }
      } else {
        this.activeTab = txt;
        url =
          campaign.fetchSingleTouchpoint +
          "/" +
          this.$route.params.id +
          "?is_processed=" +
          txt +
          "&created_by=" +
          (this.username.id || "") +
          "&search=" +
          this.filter;

        if (this.channelType && this.channelType.name) {
          url += "&channel_type=" + this.channelType.name;
        } else {
          url += "&channel_type=" + "";
        }
      }
      url += this.params;
      this.$store.commit("loader/updateStatus", true);
      try {
        const data = await this.getRequest(url);
        if (data.status) {
          this.showData = true;
          const responseData = data.response;
          this.tableData = responseData;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
      this.key += 1;
    },
    async fetchAllAdmin() {
      const url = adminUser.getAllAdminUser;
      const data = await this.getRequest(url);
      if (data.status) {
        this.getAdminUser = data.response.data;
      }
    },
    searchFor() {
      if (this.$route.name == "view-single-campaign") {
        if (this.filter.length > 0) this.fetchData(this.activeTab);
        else if (this.filter.length == 0) this.fetchData(this.activeTab);
      } else if (this.$route.name == "touchpoint-list") {
        if (this.filter.length > 0) this.fetchTouchpointData(this.activeTab);
        else if (this.filter.length == 0)
          this.fetchTouchpointData(this.activeTab);
      }
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    search(event) {
      if (this.$route.name == "view-single-campaign") {
        if (this.filter.length > 0) {
          if (event.keyCode == 13) {
            this.fetchData(this.activeTab);
          }
        } else if (this.filter.length == 0) this.fetchData(this.activeTab);
      } else if (this.$route.name == "touchpoint-list") {
        if (this.filter.length > 0) {
          if (event.keyCode == 13) {
            this.fetchTouchpointData(this.activeTab);
          }
        } else if (this.filter.length == 0)
          this.fetchTouchpointData(this.activeTab);
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    setActionType() {
      this.actionType = [
        {
          text: "Newsletter",
          value: "newsletter",
        },
        {
          text: "Case",
          value: "case",
        },
        {
          text: "Video",
          value: "video",
        },
        {
          text: "Article",
          value: "newsarticle",
        },
        {
          text: "Live Event",
          value: "live_event",
        },
        {
          text: "Post",
          value: "post",
        },
        {
          text: "Custom",
          value: "custom",
        },
      ];
    },
    setSendToMedium() {
      this.sendToMedium = [
        {
          text: "Test",
          value: "test",
        },
        {
          text: "Data Filter",
          value: "data_filter",
        },
        {
          text: "CSV",
          value: "csv",
        },
      ];
    },
    readFile(e, txt) {
      if (txt == "csv_file") {
        this.csv_file = e.target.files[0];
      } else if (txt == "image") {
        this.image = e.target.files[0];
        if (this.image.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.image.reset();
          this.image_url = "";
          this.edit.image_url = "";
          this.image = "";
          return false;
        } else {
          this.image_url = URL.createObjectURL(this.image);
          this.edit.image_url = "";
        }
        return true;
      }
    },
    changeNotificationType() {
      this.submitted = false;
      if (this.form.notification_type == "app_notification") {
        let actionTypeIndex = this.actionType.findIndex((a) => a === "custom");
        this.actionType.splice(actionTypeIndex, 1);
        this.sendToMedium = [];
        this.sendToMedium.push(
          {
            text: "Test",
            value: "test",
          },
          {
            text: "Speciality",
            value: "speciality",
          }
        );
        this.action_key += 1;
        if (this.$route.name == "add-touchpoint") {
          this.form.engagement_name = "";
          this.action_type = null;
          this.form.action_id = null;
          this.form.action_type = "";
          this.form.send_to_medium = "";
          this.form.notification_title = "";
          this.form.notification_description = "";
          this.form.device_type = "";
          this.isTestMob = false;
          this.isDataFilter = false;
          this.isSpeciality = false;
          this.isCSV = false;
          this.form.contentParams = "";
          this.edit.csv_file_url = "";
          this.image_url = "";
          this.image = "";
          this.form.mobile_numbers = "";
          this.form.scheduled_time = "";
          this.form.data_filter_id = "";
        }
      } else if (this.form.notification_type == "whatsapp") {
        this.sendToMedium = [];
        this.sendToMedium.push(
          {
            text: "Test",
            value: "test",
          },
          {
            text: "Data Filter",
            value: "data_filter",
          },
          {
            text: "CSV",
            value: "csv",
          }
        );
        this.setActionType();
        if (this.$route.name == "add-touchpoint") {
          this.form.engagement_name = "";
          this.action_type = null;
          this.form.action_id = null;
          this.form.action_type = "";
          this.form.send_to_medium = "";
          this.isTestMob = false;
          this.isDataFilter = false;
          this.isCSV = false;
          this.form.content = "";
          this.form.image = "";
          this.image = "";
          this.image_url = "";
          this.edit.csv_file_url = "";
          this.form.mobile_numbers = "";
          this.form.scheduled_time = "";
          this.form.data_filter_id = "";
          this.action_key += 1;
          this.form.variables = null;
          this.form.template_id = null;
          this.form.from_number = null;
        }
      }
    },
    changeSendType() {
      this.form.data_filter_id = "";
      this.form.mobile_numbers = "";
      this.form.speciality_selected = [];
    },
    changeType() {
      this.action_type = [];
      this.form.action_id = null;
    },
    async fetchWhatsappTemplate() {
      const url = appNotification.getWhatsapp;
      const data = await this.getRequest(url);
      if (data.status) {
        this.getWhatsappTemplate = data.response.data;
      }
    },
    async fetchSpeciality(query) {
      const url = speciality.specialityUrl;
      const data = await this.getRequest(url + "?search=" + query);
      if (data.status) {
        this.specialities = data.response.data;
      }
    },
    async fetchSingleSpeciality(id) {
      const url = speciality.specialityUrl;
      const data = await this.getRequest(url + "/" + id);
      if (data.status) {
        this.form.speciality_selected.push(data.response);
      }
    },
    async fetchAllSpeciality() {
      try {
        const url = speciality.fetchAllActiveSpeciality;
        const data = await this.getRequest(url);
        if (data.status) {
          this.form.speciality_selected = data.response.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    getVariables(val) {
      this.getWhatsappTemplate.map((item) => {
        if (item.id == val) {
          this.selectedVariable = item;
        }
      });
      this.form.variables = [];
      for (
        let index = 1;
        index <= this.selectedVariable.variable_count;
        index++
      ) {
        this.form.variables.push({
          key: index,
          value: "",
        });
      }
    },
    async fetchSingleAttachment(id) {
      if (this.form.action_type == "newsletter") {
        const url = newsletter.newsletterUrl;
        const data = await this.getRequest(url + "/" + id);
        if (data.status) {
          this.form.action_id = data.response;
        }
      } else if (this.form.action_type == "case") {
        const url = cases.caseUrl;
        const data = await this.getRequest(url + "/" + id);
        if (data.status) {
          this.form.action_id = data.response;
        }
      } else if (this.form.action_type == "video") {
        const url = video.videoUrl;
        const data = await this.getRequest(url + "/" + id);
        if (data.status) {
          this.form.action_id = data.response;
        }
      } else if (this.form.action_type == "newsarticle") {
        const url = article.articleUrl;
        const data = await this.getRequest(url + "/" + id);
        if (data.status) {
          this.form.action_id = data.response;
        }
      } else if (this.form.action_type == "live_event") {
        const url = live_event.liveEventUrl;
        const data = await this.getRequest(url + "/" + id);
        if (data.status) {
          this.form.action_id = data.response;
        }
      } else if (this.form.action_type == "post") {
        const url = post.getAllPosts;
        const data = await this.getRequest(url + "/" + id);
        if (data.status) {
          this.form.action_id = data.response;
        }
      }
    },
    async fetchAttachment(query) {
      if (this.form.action_type == "newsletter" && query != "") {
        const url = newsletter.newsletterUrl;
        const data = await this.getRequest(
          url + "?limit=50&filter=active&search=" + query
        );
        if (data.status) {
          this.action_type = data.response.data;
        }
      } else if (this.form.action_type == "case" && query != "") {
        const url = cases.caseUrl;
        const data = await this.getRequest(
          url + "?limit=50&filter=active&search=" + query
        );
        if (data.status) {
          this.action_type = data.response.data;
        }
      } else if (this.form.action_type == "video" && query != "") {
        const url = video.videoUrl;
        const data = await this.getRequest(
          url + "?limit=50&filter=active&search=" + query
        );
        if (data.status) {
          this.action_type = data.response.data;
        }
      } else if (this.form.action_type == "post" && query != "") {
        const url = post.getAllPosts;
        const data = await this.getRequest(
          url + "?limit=50&filter=active&search=" + query
        );
        if (data.status) {
          this.action_type = data.response.data;
        }
      }
    },
    async fetchAttachmentBySearch(query) {
      if (this.form.action_type == "newsarticle" && query != "") {
        const url = article.articleUrl;
        const data = await this.getRequest(
          url + "?limit=50&filter=active&search=" + query
        );
        if (data.status) {
          this.action_type = data.response.data;
        }
      }
    },
    async fetchLiveEventBySearch(query) {
      if (this.form.action_type == "live_event" && query != "") {
        const url = live_event.liveEventUrl;
        const data = await this.getRequest(
          url + "?limit=50&filter=active&search=" + query
        );
        if (data.status) {
          this.action_type = data.response.data;
        }
      }
    },
    async fetchDataFilter() {
      const url = dataFilters.fetchAllDataFilter;
      this.$store.commit("loader/updateStatus", true);
      const data = await this.getRequest(url);
      this.$store.commit("loader/updateStatus", false);
      if (data.status) {
        const responseData = data.response;

        this.dataFilter = responseData.data;
      }
    },
    async fetchAppNotification(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = appNotification.appNotificationUrl + "/" + id;
        const data = await this.getRequest(url);
        if (data.status) {
          this.dashboerd_data = JSON.parse(data.response.response);
          this.dashboerd_count = data.response.count;
          const responseData = data.response;
          if (responseData.id != null && responseData.id != "") {
            this.touchpoint_id = responseData.id;
          }
          if (
            responseData.engagement_name != null &&
            responseData.engagement_name != ""
          ) {
            this.form.engagement_name = responseData.engagement_name;
          }

          this.form.scheduled_time = moment(
            responseData.payload.scheduled_time
          ).format("YYYY-MM-DDTHH:mm");

          if (
            responseData.action_type != "" &&
            responseData.action_type != null
          ) {
            this.form.action_type = responseData.action_type;
          }
          if (responseData.action_id != null && responseData.action_id != "") {
            this.form.action_id = this.fetchSingleAttachment(
              responseData.action_id
            );
          }
          if (
            responseData.payload.content != null &&
            responseData.payload.content != ""
          ) {
            this.form.content = responseData.payload.content;
          }
          if (
            responseData.payload.notification_title != null &&
            responseData.payload.notification_title != ""
          ) {
            this.form.notification_title =
              responseData.payload.notification_title;
          }
          if (
            responseData.payload.notification_description != null &&
            responseData.payload.notification_description != ""
          ) {
            this.form.notification_description =
              responseData.payload.notification_description;
          }
          this.edit.image_url = responseData.payload.image;
          this.form.device_type = responseData.device_type;

          this.form.notification_description =
            responseData.payload.notification_description;

          if (
            responseData.payload.send_to_medium != "" &&
            responseData.payload.send_to_medium != null
          ) {
            this.form.send_to_medium = responseData.payload.send_to_medium;
          }
          if (
            responseData.data_filter_id != "" &&
            responseData.data_filter_id != null
          ) {
            this.form.data_filter_id = responseData.data_filter_id;
          }
          if (responseData.payload.send_to_medium == "speciality") {
            if (
              responseData.payload.speciality_ids != null &&
              responseData.payload.speciality_ids != ""
            ) {
              responseData.payload.speciality_ids.map((item) => {
                this.fetchSingleSpeciality(item);
              });
            }
          }
          this.edit.csv_file_url = responseData.import_csv;

          this.form.mobile_numbers = responseData.payload.mobile_numbers;
          if (
            responseData.notification_type != null &&
            responseData.notification_type != ""
          ) {
            this.form.notification_type = responseData.notification_type;
          }
          if (
            responseData.payload.variables != null &&
            responseData.payload.variables != ""
          ) {
            this.form.variables = JSON.parse(responseData.payload.variables);
          }
          if (
            responseData.payload.template_id != null &&
            responseData.payload.template_id != ""
          ) {
            this.form.template_id = responseData.payload.template_id;
          }

          if (
            responseData.payload?.from_number != null &&
            responseData.payload?.from_number != ""
          ) {
            this.form.from_number = responseData.payload.from_number;
          }
          this.oldForm = _.clone(this.form);
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async deleteAppNotification(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = appNotification.appNotificationUrl + "/" + id;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async submitData(val) {
      try {
        this.submitted = true;
        this.checkContent = true;
        this.$v.$touch();
        if (this.$v.$error) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields",
          });
          return false;
        }
        this.$store.commit("loader/updateStatus", true);
        let url = appNotification.appNotificationUrl;
        if (val == "check") {
          url = appNotification.checkApp;
        }
        let dataAppend = new FormData();
        if (this.$route.name == "add-touchpoint") {
          dataAppend.append("campaign_id", this.campaign_id);
        } else {
          dataAppend.append("campaign_id", this.$route.query.campaign_id);
        }
        for (var key in this.form) {
          if (
            key != "speciality_selected" &&
            key != "speciality_ids" &&
            key != "variables" &&
            key != "action_id" &&
            key != "csv_file" &&
            key != "image" &&
            key != "content" &&
            key != "contentParams"
          ) {
            dataAppend.append(key, this.form[key]);
          }
        }
        if (this.form.send_to_medium == "speciality") {
          for (
            let index = 0;
            index < this.form.speciality_selected.length;
            index++
          ) {
            dataAppend.append(
              "speciality_ids[]",
              this.form.speciality_selected[index].id
            );
          }
        }
        if (this.form.send_to_medium == "csv") {
          dataAppend.append("csv_file", this.csv_file);
        }
        if (this.form.action_type != "custom") {
          dataAppend.append("action_id", this.form.action_id.id);
        }
        dataAppend.append("content", this.form.content);
        dataAppend.append("image", this.image);
        dataAppend.append("variables", JSON.stringify(this.form.variables));
        const data = await this.postRequest(url, dataAppend);
        if (this.$route.name == "edit-touchpoint") {
          url = appNotification.appNotificationUrl + "/" + this.touchpoint_id;
          dataAppend.append("_method", "put");
        }
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          if (val == "check") {
            const responseData = data.response;
            if (this.form.notification_type == "whatsapp") {
              this.form.contentParams = responseData.contentParams;
              this.form.image = responseData.image;
            } else if (this.form.notification_type == "app_notification") {
              this.form.notification_title = responseData.notification_title;
              this.form.notification_description =
                responseData.notification_description;
            }
          }
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          title: err.data ? err.data.message : "Please try again!",
          icon: "error",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async viewAppReport(id) {
      try {
        const url = appNotification.appNotificationUrl + "/" + id;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.isApp = true;
          if (responseData.result != null && responseData.result != "") {
            if (
              responseData.notification_type != null &&
              responseData.notification_type != ""
            ) {
              this.report.notification_type = responseData.notification_type;
            }
            if (responseData.result != null && responseData.result != "") {
              this.report.result = responseData.result;
            }
            if (
              responseData.result.success != null &&
              responseData.result.success != ""
            ) {
              this.report.result.success = responseData.result.success;
            }
          }
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async viewWhatsappReport(id) {
      try {
        const url = appNotification.getWhatsappTemplate + id;
        const data = await this.getRequest(url);
        if (data.status) {
          this.whatsappReport = data.response;
          this.isWhatsapp = true;
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    changeVar() {
      this.checkContent = false;
    },
    async getSingleTouchpoint() {
      try {
        let url =
          campaign.analytics +
          "?touch_point_id=" +
          this.$route.query.touchpoint_id;
        let data = await this.getRequest(url);
        if (data.status) {
          this.getTouchpointAnalytics = data.url;
          this.hasData = true;
          this.noData = false;
        } else {
          this.hasData = false;
          this.noData = true;
        }
      } catch (err) {
        this.noData = true;
      }
    },
    tabActive() {
      if (!this.can("edit-communication")) {
        let index = this.fields.findIndex((item) => item.key == "edit");
        if (index !== -1) {
          this.fields.splice(index, 1);
          let table_index = this.table_header.findIndex(
            (item) => item == "edit"
          );
          this.table_header.splice(table_index, 1);
        }
      } else {
        if (!this.table_header.includes("edit")) {
          this.table_header.push("edit");
          this.fields[10] = {
            key: "edit",
          };
        }
      }
      if (!this.can("delete-communication")) {
        let index = this.fields.findIndex((item) => item.key == "delete");
        if (index !== -1) {
          this.fields.splice(index, 1);
          let table_index = this.table_header.findIndex(
            (item) => item == "delete"
          );
          this.table_header.splice(table_index, 1);
        }
      } else {
        if (!this.table_header.includes("delete")) {
          this.table_header.push("delete");
          this.fields[11] = {
            key: "delete",
          };
        }
      }
    },
  },
  watch: {
    filter: {
      handler: function () {
        this.currentPage = 1;
      },
    },
    activeTab: {
      handler: function () {
        this.currentPage = 1;
      },
    },
    currentPage: {
      handler: function (value) {
        if (this.$route.name == "view-single-campaign") {
          if (value != undefined) {
            this.params = `&page=${value}`;
            this.fetchData(this.activeTab);
            this.pageNo = this.currentPage;
          }
        } else if (this.$route.name == "touchpoint-list") {
          if (value != undefined) {
            this.params = `&page=${value}`;
            this.fetchTouchpointData(this.activeTab);
            this.pageNo = this.currentPage;
          }
        }
      },
    },
    "form.variables"(v) {
      if (v) {
        this.checkContent = false;
      }
    },
    specialityStatus(v) {
      if (v == 1) {
        this.fetchAllSpeciality();
      } else {
        if (this.$route.name == "add-touchpoint") {
          this.form.speciality_selected = [];
        } else if (this.$route.name == "edit-touchpoint") {
          this.form.speciality_selected = this.oldForm.speciality_selected;
        }
      }
    },
  },
  created() {
    this.fields.map((item) => {
      this.table_header.push(item.key);
    });
    this.tabActive();
    this.setActionType();
    this.setSendToMedium();
    if (
      this.$route.name == "add-touchpoint" ||
      this.$route.name == "edit-touchpoint"
    ) {
      this.fetchWhatsappTemplate();
      this.getWhatsappConfigNumbers();
      if (this.$route.name == "edit-touchpoint") {
        this.fetchAppNotification(this.$route.params.id);
      }
      this.fetchDataFilter();
    } else if (this.$route.name == "view-single-campaign") {
      this.fetchData(this.activeTab);
      this.fetchAllAdmin();
    } else if (this.$route.name == "touchpoint-list") {
      this.fetchTouchpointData(this.activeTab);
      this.fetchAllAdmin();
    }
    if (this.$route.name == "dashboard-touchpoint") {
      this.fetchAppNotification(this.$route.params.id);
    }
  },
};
